import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  subFont,
  mainWhite,
  screen,
} from "../../components/variables";
import { toSlug, shuffleArray } from "../../helpers";
import { Link as SpyLink } from "react-scroll";
import IconArrowSquiggly from "../../images/svg/arrow-bottom-squiggly.svg";
import {
  IconCircleCyanMd,
  IconCircleCyanLg,
  IconCircleDarkPurpleSm,
} from "../../components/circles";
import FaqRow from "../../components/faq-row";
import listPurpleArrow from "../../images/list-purple-arrow.png";
import TestimonialCarousel from "../../components/carousel/testimonial-carousel";

const Wrapper = styled.div`
  position: relative;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--header {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }
    }

    &--hyperlinks {
      display: flex;
      justify-content: center;
    }
  }

  .header {
    .col {
      &--img {
        position: relative;
        order: 2;
        max-width: 286px;
        margin: 26px 0 0 auto;
        width: 100%;
        align-self: flex-end;
        @media ${screen.xxsmall} {
          max-width: 580px;
          margin: 32px auto 0 auto;
        }
        @media ${screen.xsmall} {
          order: 0;
          width: 50%;
          max-width: none;
          margin: 0;
          padding: 0 70px 0 0;
        }

        .icon-circle {
          width: 65px;
          bottom: 110px;
          left: -32px;
          @media ${screen.xxsmall} {
            width: 85px;
            left: -12px;
          }

          &--sm {
            display: none;
            @media ${screen.xxsmall} {
              display: block;
            }
          }

          &--lg {
            @media ${screen.xxsmall} {
              display: none;
            }
          }
        }
      }

      &--text {
        @media ${screen.xsmall} {
          width: 50%;
        }

        .page-label {
          font-size: 0.93rem;
          letter-spacing: 2px;
          font-weight: 900;
          margin: 0 0 11px 0;
          display: block;
          @media ${screen.xxsmall} {
            font-size: 1.1rem;
            margin: 0 0 8px 0;
          }
        }

        .heading {
          font-weight: 900;
          line-height: 1.22;
          max-width: 500px;
          position: relative;
          font-size: 2rem;
          @media ${screen.xxsmall} {
            font-size: 3.8rem;
          }
          @media ${screen.small} {
            font-size: 5rem;
            line-height: 1.09;
          }
        }

        .description {
          display: none;
          @media ${screen.xsmall} {
            display: block;
            margin: 23px 0 38px auto;
            max-width: 590px;
          }

          p {
            margin: 0 0 21px 0;
            font-size: 1.02rem;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          strong {
            font-weight: 700;
          }

          a {
            color: ${mainCyan};
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }
    }
  }

  .hyperlinks {
    position: sticky;
    top: 60px;
    background: ${lightPurple};
    padding: 26px 32px;
    z-index: 6;
    @media ${screen.xxsmall} {
      padding: 32px 0;
    }
    @media ${screen.small} {
      padding: 34px 0;
    }

    .link {
      color: ${mainWhite};
      letter-spacing: 2px;
      font-weight: 900;
      text-transform: uppercase;
      display: block;
      margin: 0 18px;
      cursor: pointer;
      position: relative;
      font-size: 0.78rem;
      @media ${screen.xxsmall} {
        font-size: 1.05rem;
        margin: 0 38px;
      }
      @media ${screen.small} {
        font-size: 1.1rem;
      }

      &::after {
        content: "";
        height: 2px;
        background: ${mainWhite};
        width: 100%;
        display: block;
        position: absolute;
        bottom: -5px;
        left: 0;
        transform: scaleX(0);
        transition: transform 0.2s ease-in;
        transform-origin: left;
      }

      &--active {
        &::after {
          transform: scaleX(1);
        }
      }

      @media ${screen.withCursor} {
        &:hover ::after {
          transform: scaleX(1);
        }
      }
    }
  }

  .description-mobile-only {
    padding: 34px 32px;
    @media ${screen.xxsmall} {
      padding: 62px 42px;
    }
    @media ${screen.xsmall} {
      display: none;
    }

    p {
      margin: 0 0 21px 0;
      font-size: 1.02rem;
      @media ${screen.small} {
        font-size: 1.06rem;
      }
      &:last-child {
        margin: 0;
      }
    }

    strong {
      font-weight: 700;
    }

    a {
      color: ${mainCyan};
      font-weight: 700;
      @media ${screen.withCursor} {
        &:hover {
          color: ${lightPurple};
        }
      }
    }
  }

  .section {
    padding: 50px 0;
    @media ${screen.xxsmall} {
      padding: 62px 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      font-size: 1.56rem;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
      }
      @media ${screen.small} {
        font-size: 3rem;
        line-height: 1.19;
      }
    }

    .description {
      max-width: 950px;
      margin: 16px 0 0 0;
      position: relative;
      z-index: 5;
      @media ${screen.xxsmall} {
        margin: 42px 0 0 0;
      }

      p {
        margin: 0 0 21px 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        &:last-child {
          margin: 0;
        }
      }

      h1,
      h2,
      h3,
      h4 {
        font-weight: 700;
        line-height: 1.4;
        margin: 42px 0 14px 0;
        font-size: 1.25rem;
        @media ${screen.xxsmall} {
          font-size: 1.52rem;
          margin: 40px 0 20px 0;
        }
        @media ${screen.small} {
          font-size: 1.55rem;
        }
      }

      h5,
      h6 {
        font-size: 1.06rem;
        margin: 30px 0 20px 0;
        font-weight: 700;

        &:last-child {
          margin: 0;
        }
      }

      ul {
        margin: 20px 0;

        li {
          font-family: ${subFont};
          line-height: 1.42;
          position: relative;
          padding: 0 0 0 46px;
          margin: 0 0 20px 0;
          font-size: 1.15rem;
          text-transform: uppercase;
          @media ${screen.xxsmall} {
            width: 50%;
            display: inline-block;
            padding: 0 30px 0 55px;
            margin: 0 0 16px 0;
          }

          &::after {
            content: "";
            width: 40px;
            height: 40px;
            background-image: url(${listPurpleArrow});
            background-size: 26px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            left: -5px;
            top: -7px;
            @media ${screen.xxsmall} {
              left: 0;
              top: -7px;
            }
          }
        }
      }

      a {
        color: ${mainCyan};
        text-decoration: underline;
        @media ${screen.withCursor} {
          &:hover {
            color: ${lightPurple};
          }
        }
      }
    }

    &--hyper-section-one {
      background: #f4f4f4;
      position: relative;
      overflow: hidden;

      .featured-img {
        display: flex;
        flex-direction: column;
        margin: 25px 0 0 0;
        @media ${screen.xxsmall} {
          flex-direction: row;
          margin: 50px 0 0 0;
        }

        .col {
          @media ${screen.xxsmall} {
            width: 50%;
          }

          .flex {
            display: flex;
            height: 40%;
          }

          .subcol {
            width: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 130px;
            @media ${screen.xxsmall} {
              height: auto;
            }

            &--one {
              background-image: ${(props) => `url(${props.sectionOneImage1})`};
              border-right: 3px #f4f4f4 solid;
              @media ${screen.xxsmall} {
                border-right: 6px #f4f4f4 solid;
              }
            }

            &--two {
              background-image: ${(props) => `url(${props.sectionOneImage2})`};
              border-left: 3px #f4f4f4 solid;
              @media ${screen.xxsmall} {
                border-left: 6px #f4f4f4 solid;
              }
            }

            &--three {
              background-image: ${(props) => `url(${props.sectionOneImage3})`};
              height: 180px;
              width: 100%;
              border-top: 6px #f4f4f4 solid;
              @media ${screen.xxsmall} {
                height: 60%;
                border-top: 12px #f4f4f4 solid;
              }
            }
          }

          &--four {
            background-image: ${(props) => `url(${props.sectionOneImage4})`};
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 180px;
            border-top: 6px #f4f4f4 solid;
            @media ${screen.xxsmall} {
              border-left: 12px #f4f4f4 solid;
              border-top: none;
              background: none;
              height: auto;
            }

            .desktop-only {
              display: none;
              @media ${screen.xxsmall} {
                display: block;
              }
            }
          }
        }
      }

      .icon-circle {
        &--cyan {
          display: none;
          @media ${screen.small} {
            display: block;
            width: 130px;
            top: 420px;
            right: 150px;
          }
        }

        &--dark-purple {
          display: none;
          @media ${screen.small} {
            display: block;
            width: 395px;
            top: 630px;
            right: -185px;
          }
        }
      }
    }

    &--hyper-section-two {
      background: ${mainWhite};
      position: relative;

      .flex {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @media ${screen.xxsmall} {
          flex-direction: row;
        }

        .col {
          &--text {
            @media ${screen.xxsmall} {
              width: 36%;
            }

            .blockquote {
              color: ${lightPurple};
              letter-spacing: 2px;
              font-weight: 900;
              text-transform: uppercase;
              margin: 21px 0;
              border-left: 6px solid ${lightPurple};
              padding: 2px 0 0 14px;
              max-width: 370px;
              font-size: 0.94rem;
              @media ${screen.xxsmall} {
                font-size: 1.1rem;
                margin: 32px 0;
              }
            }
          }

          &--img {
            position: relative;
            width: 100%;
            margin: 0 0 70px 0;
            @media ${screen.xxsmall} {
              width: 64%;
              padding: 0 0 0 60px;
              margin: -45px 0 0 0;
            }

            .pointer {
              position: absolute;
              bottom: -46px;
              right: 130px;
              @media ${screen.xxsmall} {
                bottom: -58px;
              }

              &__arrow {
                svg {
                  width: 42px;
                  transform: rotate(62deg) scaleY(-1);
                  @media ${screen.xxsmall} {
                    width: 55px;
                  }
                }
              }

              &__text {
                font-family: ${subFont};
                letter-spacing: 1px;
                font-size: 1.16rem;
                position: absolute;
                width: 103px;
                top: 12px;
                right: -110px;
                line-height: 1.15;
                font-weight: 400;
                @media ${screen.xxsmall} {
                  font-size: 1.4rem;
                  width: 115px;
                  right: -124px;
                  top: 17px;
                }
              }
            }
          }
        }
      }

      .icon-circle {
        &--cyan {
          width: 50px;
          bottom: -25px;
          right: 5px;
          @media ${screen.xxsmall} {
            width: 200px;
            bottom: -45px;
            right: 30px;
          }
        }
      }
    }

    &--hyper-section-three {
      background: #f4f4f4;

      .heading {
        max-width: 100px;
        position: relative;

        .pointer {
          position: absolute;
          bottom: 26px;
          right: 14px;
          @media ${screen.xxsmall} {
            bottom: 37px;
            right: -35px;
          }

          &__arrow {
            svg {
              width: 42px;
              transform: rotate(-25deg);
              @media ${screen.xxsmall} {
                width: 55px;
              }
            }
          }

          &__text {
            font-family: ${subFont};
            letter-spacing: 1px;
            font-size: 1.16rem;
            position: absolute;
            width: 130px;
            top: 2px;
            right: -140px;
            line-height: 1.15;
            font-weight: 400;
            @media ${screen.xxsmall} {
              font-size: 1.4rem;
              top: 28px;
              right: -154px;
              width: 145px;
            }
          }
        }
      }

      .featured-img {
        display: flex;
        flex-direction: column;
        margin: 25px 0 0 0;
        @media ${screen.xxsmall} {
          height: calc(330px + 7vw);
          flex-direction: row;
          margin: 50px 0 0 0;
        }

        .col {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          &--left {
            background-image: ${(props) => `url(${props.sectionThreeImage1})`};
            height: 180px;
            width: 100%;
            @media ${screen.xxsmall} {
              width: 42%;
              height: auto;
            }
          }

          &--right {
            background-image: ${(props) => `url(${props.sectionThreeImage2})`};
            height: 180px;
            width: 100%;
            border-top: 6px #f4f4f4 solid;
            @media ${screen.xxsmall} {
              width: 58%;
              height: auto;
              border-left: 12px #f4f4f4 solid;
              border-top: none;
            }
          }
        }
      }
    }
  }

  .testimonial {
    margin: 0 0 20px 0;
    @media ${screen.xxsmall} {
      margin: 40px 0 80px 0;
    }
  }
`;

const HydrotherapyCentrePage = ({ data }) => {
  const {
    banner_heading,
    banner_description,
    banner_image_large,
    section_one_heading,
    section_one_description,
    section_one_images,
    section_two_heading,
    section_two_description1,
    section_two_image,
    coloured_blockquote,
    section_two_description2,
    section_three_heading,
    section_three_description,
    section_three_images,
    title_tag,
    meta_description,
  } = data.content.data;

  const testimonial_list = shuffleArray(
    data.testimonials.data.testimonial_list
  ).slice(0, 1);

  const pageURL = typeof window !== "undefined" && window.location.href;

  return (
    <Layout isHydrotherapyCentrePage noCirclesOnMobileFooter>
      <SEO
        title={title_tag || "Hydrotherapy Centre"}
        description={meta_description}
        image={banner_image_large.thumbnails.cropped.url}
        url={pageURL}
        webPageInfo={{
          name: title_tag || "Hydrotherapy Centre",
          url: pageURL,
          description: meta_description,
        }}
      />
      <Wrapper
        sectionOneImage1={section_one_images[0].image.url}
        sectionOneImage2={section_one_images[1].image.url}
        sectionOneImage3={section_one_images[2].image.url}
        sectionOneImage4={section_one_images[3].image.url}
        sectionThreeImage1={section_three_images[0].image.url}
        sectionThreeImage2={section_three_images[1].image.url}
      >
        <header className="header">
          <div className="inner-wrapper inner-wrapper--header">
            <div className="col col--img">
              <GatsbyImage
                image={banner_image_large.gatsbyImageData}
                alt={banner_image_large.alt || "Featured"}
              />

              <IconCircleCyanMd className="icon-circle icon-circle--sm" />

              <IconCircleCyanLg className="icon-circle icon-circle--lg" />
            </div>
            <div className="col col--text">
              <span className="page-label">SERVICES</span>
              <h1 className="heading">{banner_heading.text}</h1>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
          </div>
        </header>

        <nav className="hyperlinks">
          <div className="inner-wrapper inner-wrapper--hyperlinks">
            <SpyLink
              className="link"
              activeClass="link--active"
              to={toSlug(section_one_heading.text)}
              spy={true}
              smooth={true}
              offset={-125}
            >
              {section_one_heading.text}
            </SpyLink>
            <SpyLink
              className="link"
              activeClass="link--active"
              to={toSlug(section_two_heading.text)}
              spy={true}
              smooth={true}
              offset={-125}
            >
              {section_two_heading.text}
            </SpyLink>
            <SpyLink
              className="link"
              activeClass="link--active"
              to={toSlug(section_three_heading.text)}
              spy={true}
              smooth={true}
              offset={-125}
            >
              {section_three_heading.text}
            </SpyLink>
          </div>
        </nav>

        <div
          className="description-mobile-only"
          dangerouslySetInnerHTML={{ __html: banner_description.html }}
        />

        <section
          className="section section--hyper-section-one"
          id={toSlug(section_one_heading.text)}
          data-sal="fade"
          data-sal-duration="700"
        >
          <div className="inner-wrapper">
            <h2 className="heading">{section_one_heading.text}</h2>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: section_one_description.html,
              }}
            />

            <div className="featured-img">
              <div className="col">
                <div className="flex">
                  <div className="subcol subcol--one"></div>
                  <div className="subcol subcol--two"></div>
                </div>
                <div className="subcol subcol--three"></div>
              </div>

              <div className="col col--four">
                <GatsbyImage
                  className="desktop-only"
                  image={
                    section_one_images[3].image.thumbnails.cropped
                      .gatsbyImageData
                  }
                  alt={section_one_images[3].image.alt || "Featured"}
                />
              </div>
            </div>
          </div>

          <IconCircleCyanLg className="icon-circle icon-circle--cyan" />
          <IconCircleDarkPurpleSm className="icon-circle icon-circle--dark-purple" />
        </section>

        <section
          className="section section--hyper-section-two"
          id={toSlug(section_two_heading.text)}
          data-sal="fade"
          data-sal-duration="700"
        >
          <div className="inner-wrapper">
            <h2 className="heading">{section_two_heading.text}</h2>
            <div className="flex">
              <div className="col col--text">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: section_two_description1.html,
                  }}
                />
                <p className="blockquote">{coloured_blockquote}</p>
              </div>

              <div className="col col--img">
                <GatsbyImage
                  image={section_two_image.thumbnails.cropped.gatsbyImageData}
                  alt={section_two_image.alt || "Featured"}
                />

                <span className="pointer" role="presentation">
                  <span className="pointer__arrow">
                    <IconArrowSquiggly />
                  </span>
                  <span className="pointer__text">summer body ready!</span>
                </span>
              </div>
            </div>

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: section_two_description2.html,
              }}
            />
          </div>

          <IconCircleCyanLg className="icon-circle icon-circle--cyan" />
        </section>

        <section
          className="section section--hyper-section-three"
          id={toSlug(section_three_heading.text)}
        >
          <div className="inner-wrapper">
            <h2 className="heading">
              {section_three_heading.text}

              <span className="pointer" role="presentation">
                <span className="pointer__arrow">
                  <IconArrowSquiggly />
                </span>
                <span className="pointer__text">
                  had a ruff day? come play!
                </span>
              </span>
            </h2>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: section_three_description.html,
              }}
            />

            <div className="featured-img">
              <div className="col col--left"></div>
              <div className="col col--right"></div>
            </div>
          </div>
        </section>

        <section
          className="testimonial"
          data-sal="fade"
          data-sal-duration="700"
        >
          <div className="inner-wrapper">
            <TestimonialCarousel testimonial_list={testimonial_list} noDots />
          </div>
        </section>

        <FaqRow />
      </Wrapper>
    </Layout>
  );
};

export default HydrotherapyCentrePage;

export const dataQuery = graphql`
  {
    content: prismicHydrotherapyCentrePage {
      data {
        banner_heading {
          text
        }
        banner_description {
          html
        }
        banner_image_large {
          alt
          thumbnails {
            cropped {
              url
            }
          }
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        section_one_heading {
          text
        }
        section_one_description {
          html
        }
        section_one_images {
          image {
            url
            alt
            thumbnails {
              cropped {
                url
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        section_two_heading {
          text
        }
        section_two_description1 {
          html
        }
        section_two_image {
          alt
          thumbnails {
            cropped {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        coloured_blockquote
        section_two_description2 {
          html
        }
        section_three_heading {
          text
        }
        section_three_description {
          html
        }
        section_three_images {
          image {
            alt
            url
          }
        }
        title_tag
        meta_description
      }
    }

    testimonials: prismicHomePage {
      data {
        testimonial_list {
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          heading {
            text
          }
          message {
            html
          }
          from_name
        }
      }
    }
  }
`;
